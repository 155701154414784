@import "./../../resources/vars.scss";

/* ===========
    TODO LIST CONTEXT, EXPORT/IMPORT MENU
=========== */
.todoItemContext, .exportMenu, .importMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;

    > label {
        font-weight: 600;
    }
    > ul {
        list-style-type: none;
        align-self: flex-start;
        margin: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        > li {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 5px;

            i, input, h4 {
                margin-right: 10px;
            }
            h4 {
                flex: 1;
            }
        }
    }
    > button {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $font_color;
        cursor: pointer;
        border-radius: 10px;
        padding: .5rem;
        font-size: 1.25rem;
        font-weight: 600;
        background: $input_color;
        border: 3px solid transparent;
        transition: all .3s ease;
        outline: none;
        width: 100%;

        &:hover {
            background: $input_color;
            border: 3px solid $accent;
            color: $accent;
        }
        &[disabled] {
            color: $font_color_completed;
            pointer-events: none;
        }
        i {
            margin-right: 10px;
        }
        h3 {
            flex: 1;
        }
    }
}