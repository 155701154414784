@import "./resources/vars.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    background: linear-gradient(120deg, $bg1 0%, $bg2 100%) no-repeat;
    color: $header_color;
    font-family: 'Poppins', sans-serif;
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 20px;
    height: 20px;
}
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: linear-gradient(13deg, $bg2 19%, $accent 65%);
}
::-webkit-scrollbar-track{
    background: $bg2;
}

/* ===========
    EDIT TODO LIST PROMPT
=========== */
#stfDialogPlace > div > div > div > div {
    border-radius: 20px;

    > button {
        outline: none;

        svg {
            transition: all .3s ease;
        }
        &:hover svg {
            fill: $accent;
        }
    }
    > div {
        color: $font_color;
        background: $input_color;

        > input {
            padding: .35rem;
            font-size: 2rem;
            outline: none;
            background: $input_color;
            color: $font_color;
            border-radius: 10px;
            border: 3px solid transparent;
            transition: all .3s ease;

            &:focus, &:hover {
                border: 3px solid $accent;
            }
        }
        > button {
            outline: none;
            border-radius: 10px;
            padding: .5rem;
            font-size: 1.25rem;
            transition: all .3s ease;

            &:nth-child(1) {
                background: $accent;
                color: $input_color;
                border: 3px solid $accent;

                &:hover {
                    background: $input_color;
                    color: $accent;
                }
            }
            &:nth-child(2) {
                background: $input_color;
                color: $font_color;
                border: 3px solid transparent;

                &:hover {
                    background: $input_color;
                    color: $accent;
                    border: 3px solid $accent;
                }
            }
        }
    }
}