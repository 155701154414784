@import "./../../resources/vars.scss";

/* ===========
    INFORMATION
=========== */
.fabContainer {
    max-width: 50%;
    max-height: 50%;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 20px;

    .floatingBtn {
        border-radius: 50%;
        border: 3px solid transparent;
        width: 50px;
        height: 50px;
        background: $accent;
        box-shadow: 0 0 15px rgba($color: black, $alpha: .2);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $input_color;
        cursor: pointer;
        transition: all .3s ease;

        i {
            font-size: 30px;
        }
        &:hover {
            border: 3px solid $accent;
            color: $accent;
            background: $input_color;
        }
    }
    .floatingText {
        font-size: 17px;
        margin-top: 10px;
        color: $font_color;
        font-weight: 600;
        border-radius: 15px;
        padding: 2px 10px;
        background: rgba($color: white, $alpha: .7);
        box-shadow: 0 0 15px rgba($color: black, $alpha: .2);
        backdrop-filter: blur(10px);
        transition: all .3s ease;

        i {
            color: $checkbox;
            font-size: 20px;
            margin-right: 15px;
        }
        a {
            color: $accent;
            text-decoration: none;

            &:hover, &:focus {
                text-decoration: underline;
            }
        }
    }
}

/* ===========
    MOBILE DISPLAY
=========== */
@media (max-width: 700px) {
    .fabContainer {
        max-width: 100%;

        .floatingText {
            width: 100%;
        }
    }
}