@import "./../../resources/vars.scss";

/* ===========
    UNDO
=========== */
.undoBtn {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 10;
    border-radius: 50%;
    border: 3px solid transparent;
    width: 50px;
    height: 50px;
    background: $accent;
    box-shadow: 0 0 15px rgba($color: black, $alpha: .2);
    justify-content: center;
    align-items: center;
    color: $input_color;
    cursor: pointer;
    transition: all .3s ease;

    i {
        font-size: 25px;
    }
    &:hover {
        border: 3px solid $accent;
        color: $accent;
        background: $input_color;
    }
}